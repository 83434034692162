import React from 'react';
import type { FC } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { AboutPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';

interface PageProps {
  data: AboutPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="当院について"
      description="ごあいさつ。院長の池田雄太です。江東区在住10年以上。獣医腫瘍科認定医I種"
      keywords="当院について"
    />
    <article id="about" css={pageBase}>
      <h2>当院について</h2>
      <div className="skew-border"></div>
      <section>
        <h3>ごあいさつ - Self Introduction -</h3>
        <p>
          はじめまして。院長の池田雄太です。近年、犬と猫の死因の第1位は「腫瘍・がん」であり、これは我々人間と同様です。ひとえに「腫瘍」といってもその種類は無数にあり、また診断・治療法も多岐にわたるため、専門的な知識と技術、経験が必要だと考えます。私は動物病院で10年以上腫瘍科を担当し、非常に多くの患者様から「専門の先生に診てもらってよかった」という声をいただくこと、またセカンドオピニオンやサードオピニオンの依頼を受けてきた中で、「腫瘍に特化した動物病院」の必要性を感じ、この度「動物がんクリニック東京」を開院することとなりました。
        </p>
        <p>
          当院では犬と猫の腫瘍疾患に関する確実な診断と根拠に基づいた治療を提供いたします。治療においては腫瘍治療の3本柱である「外科療法、化学療法、放射線療法」をエビデンスに基づき真摯に提供する体制を完備し、また3本柱と同様に大切な「緩和ケア」にも注力し、腫瘍による疼痛と苦痛からの解放をペット、そしてご家族と協力しながら行っていきます。
        </p>
        <p>
          また、「悪性腫瘍・がん」の診療は非常にデリケートな側面もあると思われます。当院では特に患者様ご家族へのがん告知や、その後の治療においてもプライバシーが保たれた空間を提供することで、落ち着いてお話をさせていただくことが可能です。また他の患者様のことを気になさらず診療をお受けいただけます。
        </p>
        <p>
          我々は、腫瘍という大きな不安を抱えた動物とご家族に心から寄り添い、最善の道を探し、提供いたします。
        </p>
      </section>
      <section>
        <figure css={staffImage}>
          <StaticImage
            src="../images/profile.jpg"
            alt="池田雄太のプロフィール画像"
            placeholder="blurred"
          />
          <p>腫瘍科認定医Ⅰ種</p>
          <p>池田雄太</p>
          <p>愛犬 Marco</p>
          <p>江東区在住10年以上</p>
        </figure>
        <h3>資格</h3>
        <ul>
          <li>日本獣医がん学会 腫瘍科認定医Ⅰ種</li>
          <li>放射線取扱主任者第Ⅰ種</li>
          <li>日本緩和医療学会 基礎セミナー修了</li>
          <li>日本緩和医療学会 教育セミナー修了</li>
          <li>日本緩和医療学会 イーラーニング修了</li>
        </ul>
      </section>
      <section>
        <h3>所属学会</h3>
        <ul>
          <li>日本獣医がん学会</li>
          <li>日本獣医麻酔外科学会</li>
          <li>日本緩和医療学会</li>
        </ul>
      </section>
      <section>
        <h3>経歴</h3>
        <ul>
          <li>2008年　日本大学　獣医学科　卒業</li>
          <li>2008年　亀戸動物総合病院　入社</li>
          <li>2011年～2020年3月　亀戸動物総合病院　腫瘍科　担当</li>
          <li>
            2014年10月　North Carolina State University 腫瘍科にて短期研修
          </li>
          <li>
            2014年11月　The Animal Medical Center New York　腫瘍科にて短期研修
          </li>
          <li>
            2017年04月～2019年8月　東京大学附属動物医療センター　農学特定支援員
          </li>
          <li>2019年12月　動物がん診療サポート　設立</li>
          <li>2021年12月　動物がんクリニック東京　開院</li>
        </ul>
      </section>
      <section css={staff}>
        <figure css={staffImage}>
          <StaticImage
            src="../images/staff01.jpg"
            alt="川名朝香のプロフィール画像"
            placeholder="blurred"
          />
          <p css={staffProfileL}>川名朝香</p>
          <p css={staffProfileL}>博士(獣医学)</p>
        </figure>
        <h3>資格</h3>
        <ul>
          <li>獣医師</li>
          <li>博士（獣医学）</li>
        </ul>
      </section>
      <section>
        <h3>所属学会</h3>
        <ul>
          <li>日本獣医がん学会</li>
          <li>日本獣医麻酔外科学会</li>
        </ul>
      </section>
      <section>
        <h3>経歴</h3>
        <ul>
          <li>2009年　日本獣医生命科学大学　獣医学科　卒業</li>
          <li>2009年～2011年　香川県内動物病院勤務</li>
          <li>2012年～2016年　日本獣医生命科学大学　獣医生命科学研究科獣医学専攻博士課程　博士号取得</li>
          <li>2016年～2017年　東京都内動物病院勤務</li>
          <li>2017年～2022年　麻布大学附属動物医療センター　外科系、軟部組織・腫瘍外科　特任教員Ⅰ種</li>
          <li>2022年～2024年　東京農工大学農学部附属動物医療センター　腫瘍科　特任助教</li >
        </ul>
      </section>
    </article>
  </Layout>
);
export default Page;

const staffImage = css`
  margin: 0 1.5rem 1.5rem 3rem;
  width: 20%;

  p {
    text-align: center;
  }

  img {
    max-width: 100%;
  }

  @media (max-width: 560px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const staffProfileL = css`
  font-size: 1.2rem;
`;

const pageBase = css`
  @media (max-width: 560px) {
    padding: 1rem 1.5rem !important;  // TODO: index.scss撲滅 全体スタイルの設計を見直したら消せるはず
  }

  h3 {
    margin-top: 2rem !important;  // TODO: index.scss撲滅 重複しているが切り出せないため仮記載
  }

`;

const staff = css`
  margin-top: 8rem !important;  // TODO: DOM構造見直し コンテンツを意味ある単位のDOMにすべきだが無理やり調整している

`;

export const query = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
